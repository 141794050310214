// get url
const url = window.location.href;

if(url.includes('avis-clients')) {
    initGuestJs();
    progress();
}

function initGuestJs() {

    // Listener to display review by language
    // var language = document.getElementById('guestapp-language');
    // language.onchange = function() {
    //     displayReviewsByLanguage(language.value);
    // };

    // Listener to display global ratings criterias
    // var globalCriterias = document.getElementById('guestapp-show-globals-criterias');
    // globalCriterias.onclick = function() {
    //     var table = document.getElementById('guestapp-globals-criterias');
    //     if(table.classList.contains('open')) {
    //         table.classList.remove('open');
    //     } else {
    //         table.classList.add('open');
    //     }
    // }

};

// Display reviews with selected language
var displayReviewsByLanguage = function(lang) {
    var elements = document.getElementsByClassName('guestapp-review');
    for (var i=0; i < elements.length; i++) {
        var _self = elements[i];
        if (!(_self.getAttribute('data-lang') == lang) && lang != '') {
            _self.style.display = 'none';
        } else {
            _self.style.display = 'block';
        }
    }
}


function progress() {

    // Sélectionner tous les éléments avec la classe "progress"
    var progressElements = document.querySelectorAll('.progress');

    progressElements.forEach(function(progressElement) {
    var value = progressElement.getAttribute('data-value');
    var left = progressElement.querySelector('.progress-left .progress-bar');
    var right = progressElement.querySelector('.progress-right .progress-bar');

    if (value > 0) {
        if (value <= 50) {
        right.style.transform = 'rotate(' + percentageToDegrees(value) + 'deg)';
        } else {
        right.style.transform = 'rotate(180deg)';
        left.style.transform = 'rotate(' + percentageToDegrees(value - 50) + 'deg)';
        }
    }
    });

    function percentageToDegrees(percentage) {
    return (percentage / 100) * 360;
    }


};
